@font-face {
    font-family: brush-font;
    src: url(BRUSHSCI.TTF);
}
$globe-width:   12px;
$globe-height:  28px;
$globe-spacing: 40px;
$globe-spread: 3px;
$light-off-opacity: 0.4;
$balls-light-off-opacity: 0.6;

body {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fcb592;
    margin: 0;
    padding: 0;
  }
  

  .lightrope {
    top: 0;
    left: 0;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: fixed;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
    li {
      position: relative;
      animation-fill-mode: both; 
      animation-iteration-count:infinite;
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
      width: $globe-width;
      height: $globe-height;
      border-radius: 50%;
      margin: $globe-spacing/2;
      display: inline-block;
      background: rgba(0,247,165,1);
      box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,247,165,1);
      animation-name: flash-1;
      animation-duration: 2s;
      &:nth-child(2n+1) {
        background: rgba(0,255,255,1);
        box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,255,255,0.5);
        animation-name: flash-2;
        animation-duration: 0.4s;
      }
      &:nth-child(4n+2) {
        background: rgba(247,0,148,1);
        box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(247,0,148,1);
        animation-name: flash-3;
        animation-duration: 1.1s;
      }
      &:nth-child(odd) {
        animation-duration: 1.8s;
      }
      &:nth-child(3n+1) {
        animation-duration: 1.4s;
      }
      &:before {
        content: "";
        position: absolute;
        background: #222;
        width: ($globe-width - 2);
        height: $globe-height/3;
        border-radius: 3px;
        top: (0 - ($globe-height/6));
        left: 1px;
      }
      &:after {
        content: "";
        top: (0 - $globe-height/2);
        left: $globe-width - 3;
        position: absolute;
        width: $globe-spacing + 12;
        height: ($globe-height/3 * 2);
        border-bottom: solid #222 2px;
        border-radius: 50%;
      }
      &:last-child:after {
        content: none;
      }
      &:first-child {
        margin-left: -$globe-spacing;
      }
    }
  }
  @keyframes flash-1 { 
      0%, 100% { background: rgba(0,247,165,1);
      box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,247,165,1);} 
      50% { background: rgba(0,247,165,$light-off-opacity);
      box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,247,165,0.2);}
  }
  @keyframes flash-2 { 
      0%, 100% { background: rgba(0,255,255,1);
      box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,255,255,1);} 
      50% { background: rgba(0,255,255,$light-off-opacity);
      box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,255,255,0.2);}
  }
  @keyframes flash-3 { 
      0%, 100% { background: rgba(247,0,148,1);
      box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(247,0,148,1);} 
      50% { background: rgba(247,0,148,$light-off-opacity);
      box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(247,0,148,0.2);}
  }
  @keyframes flash-4 { 
      0%, 100% { background: rgba(255,0,0,1);
      box-shadow: 0px 12/6 12*2 $globe-spread rgba(255,0,0,1);} 
      50% { background: rgba(255,0,0,$balls-light-off-opacity);
      box-shadow: 0px 12/6 12*2 $globe-spread rgba(255,0,0,0.8);}
  }
  
  @keyframes flash-5 {
    0%, 100% { background: rgba(246,180,184,1);
    box-shadow: 0px 12/6 12*2 $globe-spread rgba(246,180,184,1);} 
    50% { background: rgba(246,180,184,$balls-light-off-opacity);
    box-shadow: 0px 12/6 12*2 $globe-spread rgba(246,180,184,0.8);}
}


  input#open {
    display: none;
  }
  
  .christmas-card {
    position: relative;
    width: 200px;
    height:300px;
    transform-style: preserve-3d;
      transform: perspective(2500px);
    transition: 2s;
  }
  
  
  .christmas-card:hover {
      transform: perspective(2500px) rotate(5deg);
      box-shadow: inset 100px 20px 100px rgba(0,0,0,.15), 0 10px 100px rgba(0,0,0,0.3);
  }
  /* 
  .christmas-card:hover .card-front {
    transform: rotateY(-160deg);
  }
   */
  
  .card-front {
    position: relative;
    background-color: #fff;
    width: 200px;
    height:300px;
    overflow: hidden;
    transform-origin: left;
    box-shadow: inset 100px 20px 100px rgba(0,0,0,.13), 30px 0 50px rgba(0,0,0,0.1);
    transition: 0.8s;
  }
  
  .card-front:before {
    content:"";
    position: absolute;
    width: 180px;
    height: 280px;
    background-color: #c94038;
    top: 10px;
    left: 10px;
  }
  
  .christmas-tree {
    position: absolute;
    width:0;
    height:0;
    border-right: 50px solid transparent;
    border-left: 50px solid transparent;
    border-bottom: 160px solid #034247;
    top: 75px;
    left:50px;
  }
  
  .christmas-wishes {
    position: absolute;
    width:0;
    height:0;
    border-right: 50px solid transparent;
    border-left: 50px solid transparent;
    border-bottom: 160px solid #034247;
    top: 75px;
    left:50px;
  }
  
  .christmas-wishes:before {
    content:"La multi ani";
    position: absolute;
    background-color: black;
    width:50px;
    height: 25px;
    top:120px;
    left:20px;
    box-shadow: -90px 0 #a86d16;
  }
  
  .christmas-tree:after {
    content:"";
    position: absolute;
    width: 100px;
    height: 25px;
    background-color: #c47f1a;
    left:-50px;
    top:140px;
  }
  
  .christmas-tree:before {
    content:"";
    position: absolute;
    background-color: #a86d16;
    width:50px;
    height: 25px;
    top:130px;
    left:20px;
    box-shadow: -90px 0 #a86d16;
  }
  
  .card-inside {
    position: absolute;
    background-color: #fff;
    width: 200px;
    height:300px;
    z-index:-1;
    left:0;
    top:0;
    box-shadow: inset 100px 20px 100px rgba(0,0,0,0.2);
  }
  
  .card-inside:before {
    content:"";
    position: absolute;
    width: 180px;
    height: 280px;
    background-color: #c94038;
    top: 10px;
    left: 10px;
  }
  
  .open {
    position: absolute;
    width: 200px;
    height:300px;
    left:0;
    top:0;
    background-color: transparent;
    z-index:6;
    cursor: pointer;
  }
  
  #open:checked ~ .card-front {
    transform: rotateY(-155deg);
    box-shadow: inset 100px 20px 100px rgba(0,0,0,.13), 30px 0 50px rgba(0,0,0,0.1);
  }
  
  #open:checked ~ .card-front:before {
    z-index:5;
  }
  
  
  .text {
    box-sizing: border-box;
    padding: 0 20px;
    position: absolute;
    color: white;
    font-family: brush-font;
    top:250px;
    width:200px;
    text-align: center;
    font-size:15px;
  }
  
  .text:before {
    content:"Craciun fericiiit, Roxii!";
    position: absolute;
    width:80px;
    color: #333;
    text-align: center;
    line-height: 10px;
    top:-32.5px;
    left:61px;
  }
  
  .text:after {
    content:"";
    position: absolute;
    width:0;
    height:0;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 20px solid #faa307;
    top:-185px;
    left:93px;
  }
  
  .ribbon, .ribbon:before {
    position: absolute;
    width:0;
    height:0;
    border-bottom: 12.5px solid transparent;
    border-top: 12.5px solid transparent;
  }
  
  .ribbon {
    border-left: 10px solid #c94038;
    top:205px;
    left:30px;
  }
  
  .ribbon:before {
    content:"";
    border-right: 10px solid #c94038;
    left: 120px;
    top:-12.5px;
  }
  
  .ribbon:after {
    content:"";
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius:50%;
    background-color: white;
    top:-25px;
    left:21.5px;
    box-shadow: 5px -3px white, 10px -6px white, 15px -9px white, 20px -12px white, 25px -15px white, 30px -18px white, 35px -21px white, 40px -24px white, 45px -27px white, 50px -30px white, 55px -33px white, 60px -36px white, 60px -36px white, 15px -50px white, 20px -53px white, 25px -56px white, 30px -59px white, 35px -62px white, 40px -65px white, 45px -68px white, 50px -71px white;
  }
  
  .star {
    position: absolute;
    display: block;
    width: 0px;
    height: 0px;
    border-right: 20px solid transparent;
    border-bottom: 13px solid #ffba08;
    border-left: 20px solid transparent;
    transform: rotate(-35deg);
    top:68px;
    left:80px;
      }
  
  .star:before {
        border-bottom: 13px solid #ffba08;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        height: 0;
        width: 0;
        top: -9px;
        left: -11px;
        display: block;
        content: '';
        transform: rotate(-35deg);
      }
  
  .star:after {
        position: absolute;
        display: block;
        top: 0.75px;
        left: -20px;
        width: 0px;
        height: 0px;
        border-right: 20px solid transparent;
        border-bottom: 13px solid #ffba08;
        border-left: 20px solid transparent;
        transform: rotate(-70deg);
        content:'';
  }
  
  .balls {
    position: absolute;
    width:12px;
    height: 12px;
    border-radius: 50%;
    background-color: #c94038;
    
    animation-fill-mode: both; 
    animation-iteration-count:infinite;

    animation-name: flash-4;
    animation-duration: 1.2s;
    top:180px;
    left:110px;
    box-shadow: 0px 12/6 12*2 18 #c94038;
    
    //box-shadow: -30px -30px #c94038, -18px -74px #c94038;
  }

   
  .balls4 {
    position: absolute;
    width:12px;
    height: 12px;
    border-radius: 50%;
    background-color: #c94038;
    
    animation-fill-mode: both; 
    animation-iteration-count:infinite;

    animation-name: flash-5;
    animation-duration: 1.8s;
    top:190px;
    left:90px;
    //box-shadow: -30px -30px #c94038, -18px -74px #c94038;
  }
  
  .balls5 {
    position: absolute;
    width:12px;
    height: 12px;
    border-radius: 50%;
    background-color: #c94038;
    
    animation-fill-mode: both; 
    animation-iteration-count:infinite;

    animation-name: flash-5;
    animation-duration: 2s;
    top:136px;
    left:105px;
    //box-shadow: -30px -30px #c94038, -18px -74px #c94038;
  }
  
/*   .balls:before {
    content:"";
    position: absolute;
    border-radius: 50%;
    widtH: 10px;
    height: 10px;
    background-color: #f6b4b8;
    left:-20px;
    top:10px;
    box-shadow: 15px -55px #f6b4b8;
  } */
  
  
  .balls2 {
    position: absolute;
    width:12px;
    height: 12px;
    border-radius: 50%;
    background-color: #c94038;
    
    animation-fill-mode: both; 
    animation-iteration-count:infinite;

    animation-name: flash-4;
    animation-duration: 1.4s;
    top:155px;
    left:80px;
  }

  
  .balls3 {
    position: absolute;
    width:12px;
    height: 12px;
    border-radius: 50%;
    background-color: #c94038;
    
    animation-fill-mode: both; 
    animation-iteration-count:infinite;

    animation-name: flash-4;
    animation-duration: 1.6s;
    top:109px;
    left:92px;
  }
  
  .title {
    position: absolute;
    color: #333;
    font-size:20px;
    font-family: brush-font;
    line-height: 20px;
    text-align: center;
    top: 20px;
    width:200px;
  }
  
  .wishes {
    position: absolute;
    width: 180px;
    text-align: center;
    font-family: brush-font;
    line-height:10px;
    font-size: 14px;
    color: #333;
    height: 300px;
    top: 22%;
    left:12px;
  }
  
  .gift {
    position: absolute;
    border: 3px solid #333;
    width:30px;
    height: 30px;
    top: 250px;
    left:83px;
  }
  
  .gift:before, .gift:after {
    content:"";
    position: absolute;
    background-color: #333;
  }
  
  .gift:before {
    width:30px;
    height:3px;
    top:14px;
  }
  
  .gift:after {
    height:30px;
    width:3px;
    left: 14px;
  }
  
  .bow {
    position: absolute;
  }
  
  .bow:before, .bow:after {
    content:"";
    position: absolute;
    width: 7px;
    height: 7px;
    border: 3px solid #333;
    border-radius:50%;
    top:-14px;
  }
  
  .bow:before {
    transform: skew(20deg, 10deg);
    left:2px;
  }
  
  .bow:after {
    transform: skew(-20deg,-10deg);
    left:15px;
  }